import React, {useContext, useState, useEffect} from "react";
import { UserContext } from '../Context/UserContext';

function SingleGameCurrentSeason({home_team}) {
    const {state} = useContext(UserContext);
    //console.log('user', state.user)
    const relevantGame = state.games?.find((game) => String(game.home_team) === String(home_team))
    //console.log('games', state.games)
    //console.log('relevantGame', relevantGame)
    const relevantHitterStats = state.dailyCurrentSeasonStats?.filter((stat) => stat.hitter_team === home_team || stat.pitcher_team === home_team)
    const relevantPitcherStats = state.dailyPitcherCurrentSeasonStats?.filter((stat) => stat.pitcher_team === home_team || stat.pitcher_team === relevantGame.away_team)

    const [relevantHitterState, setRelevantHitterState] = useState(relevantHitterStats ? relevantHitterStats : [])
    const [relevantPitcherState, setRelevantPitcherState] = useState(relevantPitcherStats ? relevantPitcherStats : [])

    useEffect(() => {
        setRelevantHitterState(relevantHitterStats)
        setRelevantPitcherState(relevantPitcherStats)
    }, [relevantGame])

    const subscription = state.user.subscription
    //console.log('sub', subscription)
    const hasSub = subscription && subscription.start_date && subscription.end_date ? true : false
    //console.log('hasSub?', hasSub)
    const today = new Date()
    const isActiveSub = hasSub && today >= new Date(subscription?.start_date) && today <= new Date(subscription?.end_date)
    //console.log('active sub?', isActiveSub)

    if (isActiveSub)
    return (
        <div className="ratings-container">
            <div className="table-container">
                <table className="ratings-table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Team</th>
                            <th>Bat Hand</th>
                            <th>Matchup Rating</th>
                            <th>PA</th>
                            <th>WRC+</th>
                            <th>wOBA</th>
                            <th>Matchup wOBA</th>
                            <th>wOBA Pct Change</th>
                            <th>ISO</th>
                            <th>Matchup ISO</th>
                            <th>ISO Pct Change</th>
                            <th>Hard Contact</th>
                            <th>Matchup Hard Contact</th>
                            <th>Hard Contact Pct Change</th>
                            <th>HR/FB</th>
                            <th>Matchup HR/FB</th>
                            <th>HR/FB Pct Change</th>
                            <th>FB Pct</th>
                            <th>Matchup FB Pct</th>
                            <th>FB Pct Change</th>
                            <th>SP Name</th>
                            <th>SP Team</th>
                            <th>SP Throw Hand</th>
                            <th>SP IP</th>
                            <th>SP wOBA</th>
                            <th>SP ISO</th>
                            <th>SP Hard Contact Rate</th>
                            <th>SP Soft Contact Rate</th>
                            <th>SP K Rate</th>
                            <th>SP Walk Rate</th>
                            <th>SP xFIP</th>                        
                            <th>SP FB Rate</th>
                            <th>SP GB Rate</th>
                            <th>SP HR/FB</th>
                        </tr>
                    </thead>
                    <tbody>
                        {relevantHitterState?.map((stat) => {
                            const teamStyles = {
                                backgroundColor: 
                                    (stat.hitter_team === 'ARI' ? 'rgba(167, 25, 48, 0.55)' : '') ||
                                    (stat.hitter_team === 'ATL' ? 'rgba(206, 17, 65, 0.55)' : '') ||
                                    (stat.hitter_team === 'BAL' ? 'rgba(223, 70, 1, 0.55)' : '') ||
                                    (stat.hitter_team === 'BOS' ? 'rgba(189, 48, 57, 0.55)' : '') ||
                                    (stat.hitter_team === 'CHC' ? 'rgba(14, 51, 134, 0.55)' : '') ||
                                    (stat.hitter_team === 'CHW' ? 'rgba(39, 37, 31, 0.55)' : '') ||
                                    (stat.hitter_team === 'CIN' ? 'rgba(198, 1, 31, 0.55)' : '') ||
                                    (stat.hitter_team === 'CLE' ? 'rgba(0, 56, 93, 0.55)' : '') ||
                                    (stat.hitter_team === 'COL' ? 'rgba(51, 51, 102, 0.55)' : '') ||
                                    (stat.hitter_team === 'DET' ? 'rgba(12, 35, 64, 0.55)' : '') ||
                                    (stat.hitter_team === 'HOU' ? 'rgba(0, 45, 98, 0.55)' : '') ||
                                    (stat.hitter_team === 'KCR' ? 'rgba(0, 70, 135, 0.55)' : '') ||
                                    (stat.hitter_team === 'LAA' ? 'rgba(186, 0, 33, 0.55)' : '') ||
                                    (stat.hitter_team === 'LAD' ? 'rgba(0, 90, 156, 0.55)' : '') ||
                                    (stat.hitter_team === 'MIA' ? 'rgba(0, 163, 224, 0.55)' : '') ||
                                    (stat.hitter_team === 'MIL' ? 'rgba(18, 40, 75, 0.55)' : '') ||
                                    (stat.hitter_team === 'MIN' ? 'rgba(0, 43, 92, 0.55)' : '') ||
                                    (stat.hitter_team === 'NYM' ? 'rgba(0, 45, 114, 0.55)' : '') ||
                                    (stat.hitter_team === 'NYY' ? 'rgba(0, 48, 135, 0.55)' : '') ||
                                    (stat.hitter_team === 'OAK' ? 'rgba(0, 56, 49, 0.55)' : '') ||
                                    (stat.hitter_team === 'PHI' ? 'rgba(232, 24, 40, 0.55)' : '') ||
                                    (stat.hitter_team === 'PIT' ? 'rgba(39, 37, 31, 0.55)' : '') ||
                                    (stat.hitter_team === 'STL' ? 'rgba(196, 30, 58, 0.55)' : '') ||
                                    (stat.hitter_team === 'SDP' ? 'rgba(47, 36, 29, 0.55)' : '') ||
                                    (stat.hitter_team === 'SFG' ? 'rgba(253, 90, 30, 0.55)' : '') ||
                                    (stat.hitter_team === 'SEA' ? 'rgba(12, 44, 86, 0.55)' : '') ||
                                    (stat.hitter_team === 'CIN' ? 'rgba(198, 1, 31, 0.55)' : '') ||
                                    (stat.hitter_team === 'CLE' ? 'rgba(0, 56, 93, 0.55)' : '') ||
                                    (stat.hitter_team === 'COL' ? 'rgba(51, 51, 102, 0.55)' : '') ||
                                    (stat.hitter_team === 'TBR' ? 'rgba(9, 44, 92, 0.55)' : '') ||
                                    (stat.hitter_team === 'TEX' ? 'rgba(0, 50, 120, 0.55)' : '') ||
                                    (stat.hitter_team === 'TOR' ? 'rgba(19, 74, 142, 0.55)' : '') ||
                                    (stat.hitter_team === 'WSN' ? 'rgba(171, 0, 3, 0.55)' : '')
                            };
                            const matchupRatingStyles = {
                                backgroundColor: (stat.hit_matchup_rating >= 0.450 ? 'lightblue' : '') || (stat.hit_matchup_rating >= 0.400 && stat.hit_matchup_rating < 0.450 ? 'lightgreen' : '') || (stat.hit_matchup_rating > 0.333 && stat.hit_matchup_rating < 0.400 ? 'lightyellow' : '') || (stat.hit_matchup_rating <= 0.230 ? 'lightpink' : '')
                            }
                            const matchupWOBAStyles = {
                                backgroundColor: (stat.hit_matchup_woba >= 0.400 ? 'lightblue' : '') || (stat.hit_matchup_woba < 0.400 && stat.hit_matchup_woba > 0.350 ? 'lightgreen' : '') || (stat.hit_matchup_woba >= 0.315 && stat.hit_matchup_woba < 0.350 ? 'lightyellow' : '') || (stat.hit_matchup_woba <= 0.300 ? 'lightpink' : '')
                            }
                            const matchupISOStyles = {
                                backgroundColor: (stat.hit_matchup_iso >= 0.275 ? 'lightblue' : '') || (stat.hit_matchup_iso < 0.275 && stat.hit_matchup_iso >= 0.200 ? 'lightgreen' : '') || (stat.hit_matchup_iso > 0.160 && stat.hit_matchup_iso < 0.199 ? 'lightyellow' : '') || (stat.hit_matchup_iso <= 0.150 ? 'lightpink' : '')
                            }
                            const matchupHCStyles = {
                                backgroundColor: (stat.hit_matchup_hard_contact >= 50.0 ? 'lightblue' : '') || (stat.hit_matchup_hard_contact < 50.0 && stat.hit_matchup_hard_contact >= 40.0 ? 'lightgreen' : '') || (stat.hit_matchup_hard_contact >= 33.0 && stat.hit_matchup_hard_contact < 40.0 ? 'lightyellow' : '') || (stat.hit_matchup_hard_contact <= 29.0 ? 'lightpink' : '')
                            }
                            const matchupFBStyles = {
                                backgroundColor: (stat.hit_matchup_fb_pct >= 50.0 ? 'lightblue' : '') || (stat.hit_matchup_fb_pct < 50.0 && stat.hit_matchup_fb_pct >= 40.0 ? 'lightgreen' : '') || (stat.hit_matchup_fb_pct > 37.0 && stat.hit_matchup_fb_pct < 40.0 ? 'lightyellow' : '') || (stat.hit_matchup_fb_pct <= 30.0 ? 'lightpink' : '')
                            }
                            const wOBApercentChangeStyles = {
                                color: (stat.hit_woba_percent_change > 0 ? 'rgba(0, 100, 0)' : 'rgba(139, 0, 0)')
                            }
                            const ISOpercentChangeStyles = {
                                color: (stat.hit_iso_percent_change > 0 ? 'rgba(0, 100, 0)' : 'rgba(139, 0, 0)')
                            }
                            const HCpercentChangeStyles = {
                                color: (stat.hit_hard_contact_percent_change > 0 ? 'rgba(0, 100, 0)' : 'rgba(139, 0, 0)')
                            }
                            const HRFBpercentChangeStyles = {
                                color: (stat.hit_hr_per_fb_percent_change > 0 ? 'rgba(0, 100, 0)' : 'rgba(139, 0, 0)')
                            }
                            const FBpercentChangeStyles = {
                                color: (stat.hit_fb_percent_change > 0 ? 'rgba(0, 100, 0)' : 'rgba(139, 0, 0)')
                            }
                            return (
                                <tr key={stat.id}>
                                    <td>{stat?.hitter_name}</td>
                                    <td style={teamStyles}>{stat?.hitter_team}</td>
                                    <td>{stat?.bat_hand}</td>
                                    <td style={matchupRatingStyles}>{stat?.hit_matchup_rating?.toFixed(3)}</td>
                                    <td>{stat?.hit_pa}</td>
                                    <td>{stat?.hit_wrc_plus}</td>
                                    <td>{stat?.hit_woba}</td>
                                    <td style={matchupWOBAStyles}>{stat?.hit_matchup_woba?.toFixed(3)}</td>
                                    <td style={wOBApercentChangeStyles}>{stat?.hit_woba_percent_change?.toFixed(1)}%</td>
                                    <td>{stat?.hit_iso}</td>
                                    <td style={matchupISOStyles}>{stat?.hit_matchup_iso?.toFixed(3)}</td>
                                    <td style={ISOpercentChangeStyles}>{stat?.hit_iso_percent_change?.toFixed(1)}%</td>
                                    <td>{stat?.hit_hard_contact_pct}%</td>
                                    <td style={matchupHCStyles}>{stat?.hit_matchup_hard_contact?.toFixed(1)}%</td>
                                    <td style={HCpercentChangeStyles}>{stat?.hit_hard_contact_percent_change?.toFixed(1)}%</td>
                                    <td>{stat?.hit_hr_per_fb}%</td>
                                    <td>{stat?.hit_matchup_hr_per_fb?.toFixed(1)}%</td>
                                    <td style={HRFBpercentChangeStyles}>{stat?.hit_hr_per_fb_percent_change?.toFixed(1)}%</td>
                                    <td>{stat?.hit_fb_pct}%</td>
                                    <td style={matchupFBStyles}>{stat?.hit_matchup_fb_pct?.toFixed(1)}%</td>
                                    <td style={FBpercentChangeStyles}>{stat?.hit_fb_percent_change?.toFixed(1)}%</td>
                                    <td>{stat?.pitcher_name}</td>
                                    <td>{stat?.pitcher_team}</td>
                                    <td>{stat?.throw_hand}</td>
                                    <td>{stat?.sp_ip}</td>
                                    <td>{stat?.sp_woba}</td>
                                    <td>{stat?.sp_iso}</td>
                                    <td>{stat?.sp_hard_contact.toFixed(1)}%</td>
                                    <td>{stat?.sp_soft_contact.toFixed(1)}%</td>
                                    <td>{stat?.sp_kpct.toFixed(1)}%</td>
                                    <td>{stat?.sp_bbpct.toFixed(1)}%</td>
                                    <td>{stat?.sp_xfip}</td>
                                    <td>{stat?.sp_fbpct.toFixed(1)}%</td>
                                    <td>{stat?.sp_gbpct.toFixed(1)}%</td>
                                    <td>{stat?.sp_hr_per_fb.toFixed(1)}%</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <br />
            <div className="singleGamePitcherTableContainer">
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Team</th>
                            <th>Throw Hand</th>
                            <th>Matchup Rating</th>
                            <th>IP</th>
                            <th>Avg Hitter Matchup Rating</th>
                            <th>Avg Hitter Matchup wOBA</th>
                            <th>Avg Hitter Matchup ISO</th>
                            <th>Avg Hitter Matchup Hard Contact</th>
                            <th>Avg Hitter Matchup K Pct</th>
                            <th>Avg Hitter Matchup GB Pct</th>
                            <th>Number of Elite Rated Hitters</th>
                            <th>Number of Poor Rated Hitters</th>
                            <th>Number Hitters Above MLB Avg K Pct</th>
                            <th>Number Hitters Above MLB Avg GB Pct</th>
                        </tr>
                    </thead>
                    <tbody>
                        {relevantPitcherState?.map((stat) => {
                            const teamStyles = {
                                backgroundColor: 
                                    (stat.pitcher_team === 'ARI' ? 'rgba(167, 25, 48, 0.55)' : '') ||
                                    (stat.pitcher_team === 'ATL' ? 'rgba(206, 17, 65, 0.55)' : '') ||
                                    (stat.pitcher_team === 'BAL' ? 'rgba(223, 70, 1, 0.55)' : '') ||
                                    (stat.pitcher_team === 'BOS' ? 'rgba(189, 48, 57, 0.55)' : '') ||
                                    (stat.pitcher_team === 'CHC' ? 'rgba(14, 51, 134, 0.55)' : '') ||
                                    (stat.pitcher_team === 'CHW' ? 'rgba(39, 37, 31, 0.55)' : '') ||
                                    (stat.pitcher_team === 'CIN' ? 'rgba(198, 1, 31, 0.55)' : '') ||
                                    (stat.pitcher_team === 'CLE' ? 'rgba(0, 56, 93, 0.55)' : '') ||
                                    (stat.pitcher_team === 'COL' ? 'rgba(51, 51, 102, 0.55)' : '') ||
                                    (stat.pitcher_team === 'DET' ? 'rgba(12, 35, 64, 0.55)' : '') ||
                                    (stat.pitcher_team === 'HOU' ? 'rgba(0, 45, 98, 0.55)' : '') ||
                                    (stat.pitcher_team === 'KCR' ? 'rgba(0, 70, 135, 0.55)' : '') ||
                                    (stat.pitcher_team === 'LAA' ? 'rgba(186, 0, 33, 0.55)' : '') ||
                                    (stat.pitcher_team === 'LAD' ? 'rgba(0, 90, 156, 0.55)' : '') ||
                                    (stat.pitcher_team === 'MIA' ? 'rgba(0, 163, 224, 0.55)' : '') ||
                                    (stat.pitcher_team === 'MIL' ? 'rgba(18, 40, 75, 0.55)' : '') ||
                                    (stat.pitcher_team === 'MIN' ? 'rgba(0, 43, 92, 0.55)' : '') ||
                                    (stat.pitcher_team === 'NYM' ? 'rgba(0, 45, 114, 0.55)' : '') ||
                                    (stat.pitcher_team === 'NYY' ? 'rgba(0, 48, 135, 0.55)' : '') ||
                                    (stat.pitcher_team === 'OAK' ? 'rgba(0, 56, 49, 0.55)' : '') ||
                                    (stat.pitcher_team === 'PHI' ? 'rgba(232, 24, 40, 0.55)' : '') ||
                                    (stat.pitcher_team === 'PIT' ? 'rgba(39, 37, 31, 0.55)' : '') ||
                                    (stat.pitcher_team === 'STL' ? 'rgba(196, 30, 58, 0.55)' : '') ||
                                    (stat.pitcher_team === 'SDP' ? 'rgba(47, 36, 29, 0.55)' : '') ||
                                    (stat.pitcher_team === 'SFG' ? 'rgba(253, 90, 30, 0.55)' : '') ||
                                    (stat.pitcher_team === 'SEA' ? 'rgba(12, 44, 86, 0.55)' : '') ||
                                    (stat.pitcher_team === 'CIN' ? 'rgba(198, 1, 31, 0.55)' : '') ||
                                    (stat.pitcher_team === 'CLE' ? 'rgba(0, 56, 93, 0.55)' : '') ||
                                    (stat.pitcher_team === 'COL' ? 'rgba(51, 51, 102, 0.55)' : '') ||
                                    (stat.pitcher_team === 'TBR' ? 'rgba(9, 44, 92, 0.55)' : '') ||
                                    (stat.pitcher_team === 'TEX' ? 'rgba(0, 50, 120, 0.55)' : '') ||
                                    (stat.pitcher_team === 'TOR' ? 'rgba(19, 74, 142, 0.55)' : '') ||
                                    (stat.pitcher_team === 'WSN' ? 'rgba(171, 0, 3, 0.55)' : '')
                            };
                            const strikeoutRateStyles = {
                                backgroundColor: (stat.avg_hitter_matchup_k_pct >= 30.0 ? 'lightblue' : '') || (stat.avg_hitter_matchup_k_pct >= 26.0 && stat.avg_hitter_matchup_k_pct < 30.0 ? 'lightgreen' : '') || (stat.avg_hitter_matchup_k_pct > 23.0 && stat.avg_hitter_matchup_k_pct < 26.0 ? 'lightyellow' : '') || (stat.avg_hitter_matchup_k_pct <= 20.0 ? 'lightpink' : '')
                            }
                            const groundballRateStyles = {
                                backgroundColor: (stat.avg_hitter_matchup_gb_pct >= 50.0 ? 'lightblue' : '') || (stat.avg_hitter_matchup_gb_pct >= 46.0 && stat.avg_hitter_matchup_gb_pct < 50.0 ? 'lightgreen' : '') || (stat.avg_hitter_matchup_gb_pct > 43.0 && stat.avg_hitter_matchup_gb_pct < 46.0 ? 'lightyellow' : '') || (stat.avg_hitter_matchup_gb_pct <= 37.0 ? 'lightpink' : '')
                            }
                            const hardContactStyles = {
                                backgroundColor: (stat.avg_hitter_matchup_hard_contact <= 26.0 ? 'lightblue' : '') || (stat.avg_hitter_matchup_hard_contact >= 26.01 && stat.avg_hitter_matchup_hard_contact < 29.0 ? 'lightgreen' : '') || (stat.avg_hitter_matchup_hard_contact >= 29.01 && stat.avg_hitter_matchup_hard_contact < 32.0 ? 'lightyellow' : '') || (stat.avg_hitter_matchup_hard_contact >= 36.5 ? 'lightpink' : '')
                            }
                            const isoStyles = {
                                backgroundColor: (stat.avg_hitter_matchup_iso <= 0.129 ? 'lightblue' : '') || (stat.avg_hitter_matchup_iso >= 0.130 && stat.avg_hitter_matchup_iso < 0.140 ? 'lightgreen' : '') || (stat.avg_hitter_matchup_iso >= 0.140 && stat.avg_hitter_matchup_iso < 0.150 ? 'lightyellow' : '') || (stat.avg_hitter_matchup_iso >= 0.175 ? 'lightpink' : '')
                            }
                            const wobaStyles = {
                                backgroundColor: (stat.avg_hitter_matchup_woba <= 0.280 ? 'lightblue' : '') || (stat.avg_hitter_matchup_woba > 0.280 && stat.avg_hitter_matchup_woba < 0.300 ? 'lightgreen' : '') || (stat.avg_hitter_matchup_woba >= 0.300 && stat.avg_hitter_matchup_woba < 0.330 ? 'lightyellow' : '') || (stat.avg_hitter_matchup_woba >= 0.350 ? 'lightpink' : '')
                            }
                            const pitcherMatchupRatingStyles = {
                                backgroundColor: (stat.pitcher_matchup_rating_thirty_percent <= 5.52 ? 'lightblue' : '') || (stat.pitcher_matchup_rating_thirty_percent > 5.52 && stat.pitcher_matchup_rating_thirty_percent <= 5.79 ? 'lightgreen' : '') || (stat.pitcher_matchup_rating_thirty_percent > 5.79 && stat.pitcher_matchup_rating_thirty_percent < 6.05 ? 'lightyellow' : '') || (stat.pitcher_matchup_rating_thirty_percent >= 6.4 ? 'lightpink' : '')
                            }
                            return (
                                <tr key={stat.id}>
                                    <td>{stat?.pitcher_name}</td>
                                    <td style={teamStyles}>{stat?.pitcher_team}</td>
                                    <td>{stat?.throw_hand}</td>
                                    <td style={pitcherMatchupRatingStyles}>{stat?.pitcher_matchup_rating_thirty_percent}</td>
                                    <td>{stat?.pitcher_ip}</td>
                                    <td>{stat?.avg_hitter_matchup_rating}</td>
                                    <td style={wobaStyles}>{stat?.avg_hitter_matchup_woba}</td>
                                    <td style={isoStyles}>{stat?.avg_hitter_matchup_iso}</td>
                                    <td style={hardContactStyles}>{stat?.avg_hitter_matchup_hard_contact}%</td>
                                    <td style={strikeoutRateStyles}>{stat?.avg_hitter_matchup_k_pct}%</td>
                                    <td style={groundballRateStyles}>{stat?.avg_hitter_matchup_gb_pct}%</td>
                                    <td>{stat?.num_elite_rated_hitters}</td>
                                    <td>{stat?.num_poor_rated_hitters}</td>
                                    <td>{stat?.num_hitters_above_avg_k_pct}</td>
                                    <td>{stat?.num_hitters_above_avg_gb_pct}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
    else return (
        <div className="ratings-container">
            <div className="table-container">
                <table className="ratings-table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Team</th>
                            <th>Bat Hand</th>
                            <th>Matchup Rating</th>
                            <th>PA</th>
                            <th>WRC+</th>
                            <th>wOBA</th>
                            <th>Matchup wOBA</th>
                            <th>wOBA Pct Change</th>
                            <th>ISO</th>
                            <th>Matchup ISO</th>
                            <th>ISO Pct Change</th>
                            <th>Hard Contact</th>
                            <th>Matchup Hard Contact</th>
                            <th>Hard Contact Pct Change</th>
                            <th>HR/FB</th>
                            <th>Matchup HR/FB</th>
                            <th>HR/FB Pct Change</th>
                            <th>FB Pct</th>
                            <th>Matchup FB Pct</th>
                            <th>FB Pct Change</th>
                        </tr>
                    </thead>
                    <tbody>
                        {relevantHitterState?.map((stat) => {
                            const teamStyles = {
                                backgroundColor: 
                                    (stat.hitter_team === 'ARI' ? 'rgba(167, 25, 48, 0.55)' : '') ||
                                    (stat.hitter_team === 'ATL' ? 'rgba(206, 17, 65, 0.55)' : '') ||
                                    (stat.hitter_team === 'BAL' ? 'rgba(223, 70, 1, 0.55)' : '') ||
                                    (stat.hitter_team === 'BOS' ? 'rgba(189, 48, 57, 0.55)' : '') ||
                                    (stat.hitter_team === 'CHC' ? 'rgba(14, 51, 134, 0.55)' : '') ||
                                    (stat.hitter_team === 'CHW' ? 'rgba(39, 37, 31, 0.55)' : '') ||
                                    (stat.hitter_team === 'CIN' ? 'rgba(198, 1, 31, 0.55)' : '') ||
                                    (stat.hitter_team === 'CLE' ? 'rgba(0, 56, 93, 0.55)' : '') ||
                                    (stat.hitter_team === 'COL' ? 'rgba(51, 51, 102, 0.55)' : '') ||
                                    (stat.hitter_team === 'DET' ? 'rgba(12, 35, 64, 0.55)' : '') ||
                                    (stat.hitter_team === 'HOU' ? 'rgba(0, 45, 98, 0.55)' : '') ||
                                    (stat.hitter_team === 'KCR' ? 'rgba(0, 70, 135, 0.55)' : '') ||
                                    (stat.hitter_team === 'LAA' ? 'rgba(186, 0, 33, 0.55)' : '') ||
                                    (stat.hitter_team === 'LAD' ? 'rgba(0, 90, 156, 0.55)' : '') ||
                                    (stat.hitter_team === 'MIA' ? 'rgba(0, 163, 224, 0.55)' : '') ||
                                    (stat.hitter_team === 'MIL' ? 'rgba(18, 40, 75, 0.55)' : '') ||
                                    (stat.hitter_team === 'MIN' ? 'rgba(0, 43, 92, 0.55)' : '') ||
                                    (stat.hitter_team === 'NYM' ? 'rgba(0, 45, 114, 0.55)' : '') ||
                                    (stat.hitter_team === 'NYY' ? 'rgba(0, 48, 135, 0.55)' : '') ||
                                    (stat.hitter_team === 'OAK' ? 'rgba(0, 56, 49, 0.55)' : '') ||
                                    (stat.hitter_team === 'PHI' ? 'rgba(232, 24, 40, 0.55)' : '') ||
                                    (stat.hitter_team === 'PIT' ? 'rgba(39, 37, 31, 0.55)' : '') ||
                                    (stat.hitter_team === 'STL' ? 'rgba(196, 30, 58, 0.55)' : '') ||
                                    (stat.hitter_team === 'SDP' ? 'rgba(47, 36, 29, 0.55)' : '') ||
                                    (stat.hitter_team === 'SFG' ? 'rgba(253, 90, 30, 0.55)' : '') ||
                                    (stat.hitter_team === 'SEA' ? 'rgba(12, 44, 86, 0.55)' : '') ||
                                    (stat.hitter_team === 'CIN' ? 'rgba(198, 1, 31, 0.55)' : '') ||
                                    (stat.hitter_team === 'CLE' ? 'rgba(0, 56, 93, 0.55)' : '') ||
                                    (stat.hitter_team === 'COL' ? 'rgba(51, 51, 102, 0.55)' : '') ||
                                    (stat.hitter_team === 'TBR' ? 'rgba(9, 44, 92, 0.55)' : '') ||
                                    (stat.hitter_team === 'TEX' ? 'rgba(0, 50, 120, 0.55)' : '') ||
                                    (stat.hitter_team === 'TOR' ? 'rgba(19, 74, 142, 0.55)' : '') ||
                                    (stat.hitter_team === 'WSN' ? 'rgba(171, 0, 3, 0.55)' : '')
                            };
                            const matchupRatingStyles = {
                                backgroundColor: (stat.hit_matchup_rating >= 0.450 ? 'lightblue' : '') || (stat.hit_matchup_rating >= 0.400 && stat.hit_matchup_rating < 0.450 ? 'lightgreen' : '') || (stat.hit_matchup_rating > 0.333 && stat.hit_matchup_rating < 0.400 ? 'lightyellow' : '') || (stat.hit_matchup_rating <= 0.230 ? 'lightpink' : '')
                            }
                            const matchupWOBAStyles = {
                                backgroundColor: (stat.hit_matchup_woba >= 0.400 ? 'lightblue' : '') || (stat.hit_matchup_woba < 0.400 && stat.hit_matchup_woba > 0.350 ? 'lightgreen' : '') || (stat.hit_matchup_woba >= 0.315 && stat.hit_matchup_woba < 0.350 ? 'lightyellow' : '') || (stat.hit_matchup_woba <= 0.300 ? 'lightpink' : '')
                            }
                            const matchupISOStyles = {
                                backgroundColor: (stat.hit_matchup_iso >= 0.275 ? 'lightblue' : '') || (stat.hit_matchup_iso < 0.275 && stat.hit_matchup_iso >= 0.200 ? 'lightgreen' : '') || (stat.hit_matchup_iso > 0.160 && stat.hit_matchup_iso < 0.199 ? 'lightyellow' : '') || (stat.hit_matchup_iso <= 0.150 ? 'lightpink' : '')
                            }
                            const matchupHCStyles = {
                                backgroundColor: (stat.hit_matchup_hard_contact >= 50.0 ? 'lightblue' : '') || (stat.hit_matchup_hard_contact < 50.0 && stat.hit_matchup_hard_contact >= 40.0 ? 'lightgreen' : '') || (stat.hit_matchup_hard_contact >= 33.0 && stat.hit_matchup_hard_contact < 40.0 ? 'lightyellow' : '') || (stat.hit_matchup_hard_contact <= 29.0 ? 'lightpink' : '')
                            }
                            const matchupFBStyles = {
                                backgroundColor: (stat.hit_matchup_fb_pct >= 50.0 ? 'lightblue' : '') || (stat.hit_matchup_fb_pct < 50.0 && stat.hit_matchup_fb_pct >= 40.0 ? 'lightgreen' : '') || (stat.hit_matchup_fb_pct > 37.0 && stat.hit_matchup_fb_pct < 40.0 ? 'lightyellow' : '') || (stat.hit_matchup_fb_pct <= 30.0 ? 'lightpink' : '')
                            }
                            return (
                                <tr key={stat.id}>
                                    <td>{stat?.hitter_name}</td>
                                    <td style={teamStyles}>{stat?.hitter_team}</td>
                                    <td>{stat?.bat_hand}</td>
                                    <td style={matchupRatingStyles}>{stat?.hit_matchup_rating?.toFixed(3)}</td>
                                    <td>{stat?.hit_pa}</td>
                                    <td>{stat?.hit_wrc_plus}</td>
                                    <td>{stat?.hit_woba}</td>
                                    <td style={matchupWOBAStyles}>{stat?.hit_matchup_woba?.toFixed(3)}</td>
                                    <td>{stat?.hit_woba_percent_change?.toFixed(2)}%</td>
                                    <td>{stat?.hit_iso}</td>
                                    <td style={matchupISOStyles}>{stat?.hit_matchup_iso?.toFixed(3)}</td>
                                    <td>{stat?.hit_iso_percent_change?.toFixed(2)}%</td>
                                    <td>{stat?.hit_hard_contact_pct}</td>
                                    <td style={matchupHCStyles}>{stat?.hit_matchup_hard_contact?.toFixed(2)}</td>
                                    <td>{stat?.hit_hard_contact_percent_change?.toFixed(2)}%</td>
                                    <td>{stat?.hit_hr_per_fb}</td>
                                    <td>{stat?.hit_matchup_hr_per_fb?.toFixed(2)}</td>
                                    <td>{stat?.hit_hr_per_fb_percent_change?.toFixed(2)}%</td>
                                    <td>{stat?.hit_fb_pct}</td>
                                    <td style={matchupFBStyles}>{stat?.hit_matchup_fb_pct?.toFixed(2)}</td>
                                    <td>{stat?.hit_fb_percent_change?.toFixed(2)}%</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default SingleGameCurrentSeason