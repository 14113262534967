import React, {useContext, useEffect, useState} from "react";
import { UserContext } from '../Context/UserContext'
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";

function CheckoutForm() {
    const {state, dispatch} = useContext(UserContext);
    //console.log('user', state.user)
    const stripe = useStripe()
	const elements = useElements()
    const [clientSecret, setClientSecret] = useState('')
    const [errorsState, setErrorsState] = useState([])
    const [isProcessing, setIsProcessing] = useState(false)
    const [total, setTotal] = useState(0)

    const [checkoutFormData, setCheckoutFormData] = useState({
        email: state.user.email,
        sub_type: ""
    })

    function handleChangeType(e) {
        const selectedSubType = e.target.value
        setCheckoutFormData(prevState => ({
            ...prevState,
            sub_type: e.target.value
        }))
        let newTotal = 0;
        switch (selectedSubType) {
            case 'Season Pass':
                newTotal = 10;
                break;
            case 'Monthly Pass':
                newTotal = 20;
                break;
            case 'Day Pass':
                newTotal = 3;
                break;
            default:
                break;
        }
        setTotal(newTotal)
    }

    function handleChangeEmail(e) {
        setCheckoutFormData(prevState => ({
            ...prevState,
            email: e.target.value
        }))
    }

    function handleSubmit(e) {
        e.preventDefault()
        if (!stripe || !elements) {
            return
        }

        if (checkoutFormData.sub_type === '') {
            alert('Must select a subscription type from the dropdown');
            return;
        }    

        setIsProcessing(true)

        elements.submit().then(result => {
            //console.log('result', result)
            if (result.error) {
                setIsProcessing(false);
                alert('Error submitting payment details:', result.error);
                return;
            }
            fetch('/payment_intents', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user_id: state.user.id,
                    sub_type: checkoutFormData.sub_type,
                    email: checkoutFormData.email,
                })
            })
            .then(res => res.json())
            .then(paymentIntent => {
                setClientSecret(paymentIntent.client_secret)
            })
        })
    }

    useEffect(() => {
        if (clientSecret && stripe && elements) {
            stripe.confirmPayment({
                elements,
                clientSecret,
                confirmParams: {
                    return_url: `${window.location.origin}/completion`
                },
                redirect: 'if_required'
            })
            .then((data) => {
                //console.log('data after confirmCardPayment', data)
                if (data.error) {
                    setIsProcessing(false)
                    alert(data.error.message)
                }
                else if (data.paymentIntent.status === "succeeded") {
                    dispatch({type: 'setPaymentStatus', payload: true})
                    //console.log('new sub user', state.user)
                }
            })
        }
    }, [clientSecret])

    if (errorsState.length > 0)
    return (
        <div className="checkoutFormContainer">
            <form className="payment-form" onSubmit={handleSubmit}>
                <label>Email: </label>
                <input id="email" type="text" value={checkoutFormData.email} onChange={handleChangeEmail} placeholder="Enter email address" />
                <br />
                <label>
                    Choose a Subscription Type:
                    <select value={checkoutFormData.sub_type} onChange={handleChangeType}>
                        <option value="">Select...</option>
                        <option value="Season Pass">Season Pass</option>
                        {/* <option value="Monthly Pass">Monthly Pass</option> */}
                        <option value="Day Pass">Day Pass</option>
                    </select>
                </label>
                <br />
                <label>Total</label>
                <input id="totalInput" type="text" value={`$${total}`} onChange={handleChangeType} />
                <PaymentElement className="paymentElement" />
                <h3>All Sales Final. No Refunds</h3>
                <button disabled={isProcessing} type="submit">{isProcessing ? "Processing..." : "Pay Now"}</button>
            </form>
            {errorsState}
        </div>
    )
    else if (state.paymentStatus) {
        return (
            <div className="checkoutFormContainer">
                <h3>Payment Successful. Enjoy your premium membership!</h3>
                <form className="payment-form" onSubmit={handleSubmit}>
                    <label>Email: </label>
                    <input id="email" type="text" value={checkoutFormData.email} onChange={handleChangeEmail} placeholder="Enter email address" />
                    <br />
                    <label>
                        Choose a Subscription Type:
                        <select value={checkoutFormData.sub_type} onChange={handleChangeType}>
                            <option value="">Select...</option>
                            <option value="Season Pass">Season Pass</option>
                            {/* <option value="Monthly Pass">Monthly Pass</option> */}
                            <option value="Day Pass">Day Pass</option>
                        </select>
                    </label>
                    <br />
                    <label>Total</label>
                    <input id="totalInput" type="text" value={`$${total}`} onChange={handleChangeType} />
                    <PaymentElement className="paymentElement" />
                    <h3>All Sales Final. No Refunds</h3>
                    <button disabled={isProcessing} type="submit">{isProcessing ? "Processing..." : "Pay Now"}</button>
                </form>
            </div>
        )
    }
    else return (
        <div className="checkoutFormContainer">
            <form className="payment-form" onSubmit={handleSubmit}>
                <label>Email: </label>
                <input id="email" type="text" value={checkoutFormData.email} onChange={handleChangeEmail} placeholder="Enter email address" />
                <br />
                <label>
                    Choose a Subscription Type:
                    <select value={checkoutFormData.sub_type} onChange={handleChangeType}>
                        <option value="">Select...</option>
                        <option value="Season Pass">Season Pass</option>
                        {/* <option value="Monthly Pass">Monthly Pass</option> */}
                        <option value="Day Pass">Day Pass</option>
                    </select>
                </label>
                <br />
                <label>Total</label>
                <input id="totalInput" type="text" value={`$${total}`} onChange={handleChangeType} />
                <PaymentElement className="paymentElement"/>
                <h3>All Sales Final. No Refunds</h3>
                <button disabled={isProcessing} type="submit">{isProcessing ? "Processing..." : "Pay Now"}</button>
            </form>
        </div>
    )
}

export default CheckoutForm